import React, { useRef } from 'react';

import './index.css';
import { useCompleteOnScreen } from '../../../utils/hooks';
import { AnimatedDots } from '../../Dots';

const Perks = () => {
  const ref = useRef();
  const isCompleteOnScreen = useCompleteOnScreen(ref);

  return (
    <div ref={ref} className="perks-section secondary-padding">
      <div className="heading">
        <p className="title">Perks of working at Devetry</p>
        <AnimatedDots completeOnScreen={isCompleteOnScreen} />
      </div>
      <div className="perks-list">
        <ul>
          <li>
            <p>Strong Health, Dental, and Vision coverage</p>
          </li>
          <li>
            <p>Matching 401(k) Plan (SafeHarbor Plan)</p>
          </li>
          <li>
            <p>Unlimited paid time off</p>
          </li>
          <li>
            <p>Gym membership reimbursement</p>
          </li>
          <li>
            <p>WFH currently with (optional) Fridays when the office reopens</p>
          </li>
        </ul>
        <ul>
          <li>
            <p>“People first” culture = empowering team members to grow and thrive
              by providing autonomy, professional development opportunities, and
              above all else, mutual respect for one another</p>
          </li>
          <li>
            <p>Monthly lunch + learns</p>
          </li>
          <li>
            <p>Annual company meeting in Amsterdam</p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Perks;
