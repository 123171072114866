import React, { useEffect, useState } from 'react';

import './index.css';
import CareerList from '../../../CareerList';

const MobileView = ({ items, avalailableCategories }) => {
  const [categoriesToShow, setCategoriesToShow] = useState([]);

  useEffect(() => {
    const categoriesToShow = [];
    avalailableCategories.forEach((category) => {
      const showCat = items.some((item) => item.category === category);
      if (showCat) categoriesToShow.push(category);
    });
    setCategoriesToShow(categoriesToShow);
  }, [items]);

  const setCategoryQuantity = (category) => {
    let quantity = 0;
    items.forEach((item) => {
      if (item.category === category) quantity++;
    });
    return category.concat(` (${quantity})`);
  };

  const filterByCategory = (category) => {
    return items.filter((item) => item.category === category);
  };

  return (
    <div className="careers-mobile-view">
      {categoriesToShow.map((category, index) => (
        <div key={index}>
          <p className="category-title">{setCategoryQuantity(category)}</p>
          <CareerList items={filterByCategory(category)} />
        </div>
      ))}
    </div>
  );
};

export default MobileView;
