import React from 'react';

import './index.css';

const FollowSection = () => {
  return (
    <section className="follow-us-section secondary-padding">
      <p>
        Don’t see your position here? Follow us on LinkedIn to see new positions
        as they open.
      </p>
      <a
        href="https://www.linkedin.com/company/devetry-inc-/"
        target="_blank"
        rel="noreferrer"
      >
        Go to LinkedIn
      </a>
    </section>
  );
};

export default FollowSection;
