import React, { useRef, useState, useEffect } from 'react';

import './index.css';
import { getCareers } from '../../../services/CareersService';
import { useCompleteOnScreen } from '../../../utils/hooks';
import { AnimatedDots } from '../../Dots';
import DesktopTabletView from './DesktopTabletView';
import MobileView from './MobileView';

const avalailableCategories = [
  'Operations',
  'Business Analysis',
  'Design',
  'Development',
  'Other'
];

const OpenCareersSection = () => {
  const ref = useRef();
  const [careers, setCareers] = useState(undefined);
  const isCompleteOnScreen = useCompleteOnScreen(ref);

  useEffect(() => {
    getCareers().then((result) => setCareers(result));
  }, []);

  return (
    <section ref={ref} className="open-careers">
      <div className="heading secondary-padding">
        <p className="title">Open careers</p>
        <AnimatedDots completeOnScreen={isCompleteOnScreen} />
      </div>
      <div className="desktop-tablet-view secondary-padding">
        <DesktopTabletView
          items={careers || []}
          avalailableCategories={avalailableCategories}
        />
      </div>
      <div className="mobile-view secondary-padding">
        <MobileView
          items={careers || []}
          avalailableCategories={avalailableCategories}
        />
      </div>
    </section>
  );
};

export default OpenCareersSection;
