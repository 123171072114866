import React from 'react';

import './index.css';
import orangeEllipse from '../../../img/orange-ellipse.svg';
import dots from '../../../img/work-dots.svg';
import whatWeDoTechnology from '../../../img/what-we-do-technology.jpg';
import whateWeDoSoftware from '../../../img/what-we-do-software.jpg';
import zigzag from '../../../img/zigzag.svg';
import greenEllipse from '../../../img/green-ellipse.svg' 

const WhatWeDo = () => {
  return (
    <section className="what-we-do-section root-padding">
      <img
        src={orangeEllipse}
        alt="orange-ellipse"
        className="orange-ellipse"
      />
      <img
        src={dots}
        alt="dots"
        className="dots"
      />
      <div className="image-container first">
        <p>
          We help companies <strong>transform into technology leaders.</strong>
        </p>
        <img
          src={whatWeDoTechnology}
          alt="what-we-do-technology"
        />
      </div>
      <div className="image-container last">
        <p>
          We create <strong>software solutions</strong> and empower our partners
          to <strong>leverage technology.</strong>
        </p>
        <img
          src={whateWeDoSoftware}
          alt="what-we-do-software"
        />
        <img
          src={zigzag}
          alt="zigzag"
          className="zigzag"
        />
        <img
          src={greenEllipse}
          alt="green-ellipse"
          className="green-ellipse"
        />
      </div>
    </section>
  );
};

export default WhatWeDo;
