import React from 'react';

import './index.css';
import dots from '../../../img/work-dots.svg';
import orangeEllipse from '../../../img/orange-ellipse.svg';
import Img from 'gatsby-image';

const CareersHeroSection = ({image}) => {
  return (
    <section className="career-hero">
      <div className="left root-padding">
        <img
          className="dots-bottom"
          src={dots}
          alt="Dots"
        />
        <img
          className="orange-ellipse"
          src={orangeEllipse}
        />
        <div className="red-dot" />
        <h1>Careers</h1>
        <h2>Find something that fills your cup.</h2>
      </div>
      <div className="right" >
        <Img fluid={image.childImageSharp.fluid} alt="team playing ping pong" />
      </div>
    </section>
  );
};

export default CareersHeroSection;
