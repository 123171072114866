import React, { useRef } from 'react';

import './index.css';
import { useCompleteOnScreen } from '../../../utils/hooks';
import { AnimatedDots } from '../../Dots';

const Opportunities = () => {
  const ref = useRef();
  const isCompleteOnScreen = useCompleteOnScreen(ref);

  return (
    <section ref={ref} className="opportunities-section root-padding">
      <div className="heading">
        <p className="title">Equal Opportunity at Devetry</p>
        <AnimatedDots completeOnScreen={isCompleteOnScreen} />
      </div>
      <div className="information-container">
        <p className="title">Equal pay for equal work</p>
        <div className="content-container">
          <p>
            Devetry abides by the Equal Pay for Equal Work Act, which prohibits
            wages based on sex or wage history. All salary ranges are public and
            are awarded based on merit, experience, seniority, location,
            education/training, and production/contribution.
          </p>
          <p>
            Devetry is proud to be an Equal Employment Opportunity. We do not
            discriminate based on gender, race or color, ethnicity or national
            origin, age, disability, religion, sexual orientation, gender
            identity or expression, veteran status, or any other applicable
            characteristics protected by law.
          </p>
          <p>
            If you have a disability or special need that requires accommodation
            to navigate our website or apply for a job, please contact&nbsp;
            <a href="mailto:info@devetry.com">info@devetry.com</a>.
          </p>
        </div>
      </div>
      <div className="grey-circle"></div>
    </section>
  );
};

export default Opportunities;
