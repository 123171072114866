import React, { useRef } from 'react';

import './index.css';
import { useCompleteOnScreen } from '../../utils/hooks';
import { AnimatedDots } from '../Dots';
import peopleIcon from '../../img/people-people-icon.svg';
import peopleDotsRight from '../../img/people-right-dots.svg';
import peopleIntegrity from '../../img/people-integrity-icon.svg';
import peopleDotsLeft from '../../img/people-left-dots.svg';
import peopleCourage from '../../img/people-courage-icon.svg';
import peopleGrit from '../../img/people-grit-icon.svg';

const CoreValues = () => {
  const ref = useRef();
  const isCompleteOnScreen = useCompleteOnScreen(ref);

  return (
    <section className="core-values root-padding" ref={ref}>
      <div className="left">
        <AnimatedDots
          completeOnScreen={isCompleteOnScreen}
          color="var(--green)"
        />
        <p className="heading">Our Core Values</p>
        <p className="description">
          At Devetry, we believe in continuous learning, collaborative problem
          solving, and a respectful work environment. Each person here embodies
          the company’s core values of people-first, integrity, courage, and
          grit.
        </p>
      </div>
      <div className="right">
        <div className="up-section">
          <div className="down">
            <p className="people-tag tag">People</p>
            <img
              src={peopleIcon}
              alt="people"
              className="people-icons"
            />
          </div>
          <img
            src={peopleDotsRight}
            alt="dots-right"
            className="people-dots"
          />
          <div className="up">
            <img
              src={peopleIntegrity}
              alt="integrity"
              className="people-icons"
            />
            <p className="integrity-tag tag">Integrity</p>
          </div>
        </div>
        <div className="down-section">
          <img
            src={peopleDotsLeft}
            alt="dots-left"
            className="people-dots"
          />
          <div className="down">
            <p className="courage-tag tag">Courage</p>
            <img
              src={peopleCourage}
              alt="courage"
              className="people-icons"
            />
          </div>
          <img
            src={peopleDotsRight}
            alt="dots-right"
            className="people-dots"
          />
          <div className="up">
            <img
              src={peopleGrit}
              alt="grit"
              className="people-icons"
            />
            <p className="grit-tag tag">Grit</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CoreValues;
