import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import AppContext from '../../contexts/AppContext';

import './index.css';

// Sections
import HeroSection from '../../components/careersSection/Hero';
import OpenCareersSection from '../../components/careersSection/OpenCareers';
import FollowSection from '../../components/careersSection/Follow';
import WhatWeDoSection from '../../components/careersSection/WhatWeDo';
import CoreValuesSection from '../../components/CoreValues';
import PerksSection from '../../components/careersSection/Perks';
import BestBlogs from '../../components/BestBlogs';
import RedSection from '../../components/UXUISections/Red';
import RedBody from '../../components/careersSection/RedBody';
import OpportunitiesSection from '../../components/careersSection/Opportunities';

const pageTitle = 'Careers at Devetry | Engineering Jobs Denver | Devetry';
const pageDescription =
  'Explore life at Devetry. View our current open positions and apply today!';

const CareersPageTemplate = ({ blogs, heroImage }) => {
  const { setActivePage } = useContext(AppContext);

  useEffect(() => {
    setActivePage('people');
  }, [setActivePage]);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
      </Helmet>
      <main className="root-container">
        <HeroSection image={heroImage} />
        <OpenCareersSection />
        <FollowSection />
        <WhatWeDoSection />
        <CoreValuesSection />
        <section className="shared-background">
          <PerksSection />
          <BestBlogs items={blogs} title="Devetry News" />
        </section>
        <RedSection title="We want your uniqueness" body={<RedBody />} />
        <OpportunitiesSection />
      </main>
    </>
  );
};

const CareersPage = ({ data }) => {
  const { edges } = data.allMarkdownRemark;
  const careersBlogs = edges.map((e) => ({
    readingTime: e.node.fields.readingTime.text,
    slug: e.node.fields.slug,
    ...e.node.frontmatter
  }));
  const heroImage = data.file;

  return <CareersPageTemplate blogs={careersBlogs} heroImage={heroImage} />;
};

CareersPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default CareersPage;

export const pageQuery = graphql`
  query CareersPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "Careers/index" } }) {
      frontmatter {
        templateKey
      }
    }
    allMarkdownRemark(
      filter: { published: { eq: true }, frontmatter: { templateKey: { eq: "Blog/index" } } }
      limit: 3
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
            readingTime {
              text
            }
          }
          frontmatter {
            title
            date
            category
          }
        }
      }
    }
    file(relativePath: {
      eq: "ping-pong.jpg"
    }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 60){
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
