import React from 'react';

import './index.css';

export default () => {
  return (
    <span className="careers-red-content">
      Devetry is dedicated to improving the diversity of applicants, hires, and
      leadership. <br />
      <br /> We understand that diversity in tech is a problem. While there is
      no silver bullet, we’re actively working on and implementing initiatives
      that cultivate a culture of inclusion and diversity. Some of our past and
      current programs include:
      <ul>
        <li>
          <p>Code Forward, a free software development training program</p>
        </li>
        <li>
          <p>A hiring process that recognizes biases and works to
          neutralize them</p>
        </li>
        <li>
          <p>Candidate rubrics that standardize skills/attributes</p>
        </li>
        <li>
          <p>Company-wide unconscious bias training</p>
        </li>
        <li>
          <p>Weekly office hours to increase knowledge sharing</p>
        </li>
        <li>
          <p>Two days of paid professional development per team member, per year</p>
        </li>
        <li>
          <p>A culture where communication and trust is prioritized</p>
        </li>
      </ul>
    </span>
  );
};
